<div class="my-container">
    <mat-menu #usermenu="matMenu" class="profile-menu" style="padding: 8px !important;">
      <div class="pp-header">
        <div class="img"><img src="./assets/images/user.png" alt="Image"></div>
        <div>
          <span>{{ loggedInUser.username }}</span>
        </div>
      </div>
      <div style="padding: 0px 8px;"><mat-divider style="border-top-color: grey;"></mat-divider></div>

      <button mat-menu-item [routerLink]="['my-profile']" routerLinkActive="router-link-active">
        <mat-icon>account_circle</mat-icon>
        <span>My Profile</span>
      </button>
      <button mat-menu-item [routerLink]="['change-password']" routerLinkActive="router-link-active">
        <mat-icon>key</mat-icon>
        <span>Change Password</span>
      </button>
      <!-- <mat-divider></mat-divider> -->
      <button mat-menu-item (click)="signout()">
        <mat-icon>logout</mat-icon>
        <span>logout</span>
      </button>
    </mat-menu>


    <!-- Header Content End -->

    <mat-sidenav-container class="my-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
      <!-- Side-Nav Menu -->
      <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
        [(opened)]="mobileQuery.matches ? !opened : opened" class="my-sidenav">
        <!-- logo for REVFUEL -->
        <div class="logo-box1 logo-box">
          <button mat-icon-button (click)="snav.toggle();isSideNavOpen=!isSideNavOpen" class="my-icon menu-toggle"
            aria-label="Icon-button with menu icon">
            <mat-icon><img src="./assets/images/toggle1.png" class="toggle666" alt="Image"></mat-icon>
          </button>
          <div class="layout-header-logo" [routerLink]="['/home']">
            <img (click)="routeToHome()" src="./assets/images/rev_logo_white.png" width="60%" alt="Logo" />
          </div>
        </div>
        <mat-nav-list>
          <app-menu-list-item *ngFor="let item of navMenu" [item]="item"></app-menu-list-item>
        </mat-nav-list>
      </mat-sidenav>


      <!-- Side-Nav Menu End -->

      <mat-sidenav-content class="app-pages" [ngClass]="{'content-full':!isSideNavOpen}">
        <mat-toolbar [ngClass]="{'topbar-fixed':!isSideNavOpen}" color="primary" class="my-toolbar">

          <button *ngIf="mobileQuery.matches" mat-icon-button (click)="snav.open();isSideNavOpen=true"
            class="my-icon menu-toggle" aria-label="Icon-button with menu icon">
            <mat-icon><img src="./assets/images/tottle-1-black.png" alt="Image"></mat-icon>
          </button>

          <span class="my-spacer"></span>
          <!-- <div class="form-group search-form">
            <mat-form-field>
              <input matInput placeholder="Search Here..." />
              <img src="./assets/images/search.png" class="search-icon" alt="Image">
            </mat-form-field>
          </div> -->
          <!-- <div class="static-section">
            <mat-icon matBadgeColor="warn"><img src="./assets/images/static.png" alt="Image"></mat-icon>
          </div> -->
          <!-- <div class="Mail-section">
            <mat-icon matBadge="15" matBadgeColor="warn"><img src="./assets/images/email.png"
                alt="Image"></mat-icon>
            <span class="cdk-visually-hidden">
            </span>
          </div> -->
          <!-- <div class="Home-section">
            <mat-icon matBadge="15" matBadgeColor="warn"><img src="./assets/images/bell.png"
                alt="Image"></mat-icon>
            <span class="cdk-visually-hidden">
            </span>
          </div> -->

          <h3 style="margin: 10px;">
            {{ loggedInUser.username }} | {{ loggedInUser.roles[0] }}
          </h3>
          <button mat-icon-button [matMenuTriggerFor]="usermenu" aria-label="menu button" class="profile-menu">
            <mat-icon><img src="./assets/images/user.png" class="user1" alt="Image"></mat-icon>
          </button>
        </mat-toolbar>
        <section class="layout-content">
          <ng-content select="router-outlet"></ng-content>
        </section>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
