import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../../environments/environment";
import { LoginOptionDto, UserDto } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUser = UserDto.ANONYMOUS;
  public readonly ROLES = { 
    ADMIN : { key : "ADMIN", prefix: "/admin" } ,
    CUSTOMER : { key : "CUSTOMER", prefix: "/customer" }
  }

  constructor(private httpClient: HttpClient) {
  }

  getLoginOptions(): Observable<LoginOptionDto[]> {
    return this.httpClient.get<LoginOptionDto[]>('/ui-bff/auth/login-options');
  }

  //determine if user is admin | customer
  is(role : string) {
    return this.currentUser.roles.includes(role)
  }

  currentRoles() {
    return {
      admin : this.is(this.ROLES.ADMIN.key),
      customer : this.is(this.ROLES.CUSTOMER.key)
    }
  }

  getRole() {
    return this.currentUser.roles?.length > 0 ? this.currentUser.roles[0] : "NA"
  }

  getRoleBasedURL(url:string) {
    let prefix = this.ROLES.CUSTOMER.prefix

    if(this.currentUser.roles.includes(this.ROLES.ADMIN.key)) {
      prefix = this.ROLES.ADMIN.prefix
    }//more roles

    console.log("Route to :" , prefix+url)
    return prefix.concat(url);
  }

  getCurrentUser(): Observable<UserDto> {
    return this.httpClient.get<UserDto>('/ui-bff/auth/user').pipe(
      map(response => new UserDto(response.username,response.roles || [],response.authenticated,response.userId)),
      tap(user => this.currentUser = user),
      catchError(() => of(UserDto.ANONYMOUS))
    );
  }

  logout(): void {
    this.httpClient.post('/ui-bff/logout', {}, {
      headers: { 'X-POST-LOGOUT-REDIRECT-URI': `${environment.baseUrl}` },
      observe: 'response', responseType :'arraybuffer'
    }).subscribe({
      next: (response:any) => {
        window.location.href = response.url;
      }, 
      error : () => {
      }, 
      complete : () => this.currentUser = UserDto.ANONYMOUS
    })
  }
}
