export interface LoginOptionDto {
  label: string;
  loginUri: string;
  isSameAuthority: boolean;
}

export class UserDto {
  static readonly ANONYMOUS = new UserDto('', [], false,0);

  readonly username: string;
  readonly roles: string[];
  readonly authenticated: boolean;
  readonly userId: number;

  constructor(username: string,roles:string[],authenticated:boolean,userId:number) {
    this.roles = roles
    this.username = username
    this.authenticated = authenticated
    this.userId = userId
  }

}

export interface PaginatedResponse<T> {
  totalElements:number;
  content : T[];
  pageable? : { pageNumber:number,pageSize:number }
}

export interface User {
  id: string
  createdTimestamp : number
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: string;
  attributes: any;
  isEnabled: boolean;
}

export interface Customer {
  id: number
  factorSoftCustomerId: string;
  usdotNumber: string;
  name: string;
  email: string;
  alternativeEmail: string;
  phoneNumber: string;
  secretKey: string;
  notes: string;
  status: string;
  overAllLimit: number;
  cashAdvanceLimit: number;
  dieselLimit: number;
  reeferLimit: number;
  defLimit: number;
}


export interface AssignData {
    name: string | number;
    customerId: string | number;
    status: string | number;
    isChecked: boolean;
    disabled: boolean;
  }

export interface Card {
  id: number;
  bank: string;
  number: string | number;
  customerId: string | number;
  driverId: number;
  status: string;
  name: string;
}

export interface CardSearchParam {
  bank?:string,
  status?:string,
  customerId?:number,
  cardNumber?:number,
  last4CardNumber?:number,
  driverId?:number,
  page:number,
  size:number
}

export interface TransactionSearchParam {
  startDate?:string,
  endDate?:string,
  bank?:string,
  status?:string,
  customerId?:number,
  cardNumber?:number,
  driverId?:number[],
  page:number,
  size:number
}

export interface InvoiceSearchParam {
  startDate?:string,
  endDate?:string,
  status?:string,
  customerId?:number,
  page:number,
  size:number
}

export interface RatePlanSearchParam {
  customerId?:number,
  vendorId?:string,
  page:number,
  size:number
}

export interface DriverSearchParam {
  customerId?:number,
  searchQuery?:string,
  page:number,
  size:number
}

export interface FuelPricesSearchParam {
  customerId:number,
  vendor:string,
  date:String,
  siteCode?:String
}

export interface Customer {
  id: number;
  name: string;
  email: string;
  overAllLimit: number;
  status: string;
}

export interface RatePlan {
  id: number;
  customerId: string | number;
  vendorId: string | number;
  startDate: string | number;
  endDate: string | number;
  pricingMethod: string | number;
  pricingValue: string | number;
  sendPriceFile : boolean;
}

export interface CardSettings {
  customerId: string,
  overallLimit: number,
  cashAdvanceLimit: number,
  diesalLimit: number,
  reeferLimit: number,
  defLimit: number,
  purchaseLimit: number,
  chekLimit: number,
  tCheckLimit: number,
  loadCashLimit: number,
  allowLoadCashAccess: boolean,
  allowUltramarCardAccess: boolean,
  vehicleNonMandatory: boolean,
  enableCheckIssueAlertAdmin: boolean
}

export interface UltramarCardLimits {
  cashAdvanceLimit: number,
  dieselLimit: number,
  reeferLimit: number,
  defLimit: number
}

export interface Driver {
  id:number,
  customerId:number,
  firstName:string,
  creationDate:Date,
  lastName:string,
  licenseNumber:string,
  licenseState:string,
  trailerNumber:string,
}

export interface Transaction {
  id: number,
  customerId: number,
  transactionDateTime: string,
  transactionTotal: number,
  transactionCurrency: string,
  cardId: number,
  cardNumber: string,
  ratePlanId: string,
  bankTotal: number,
  bankCurrency: string,
  truckStopCode: string,
  truckStopVendor: string,
  truckStopName: string,
  truckStopCity: string,
  truckStopState: string,
  driverId: number,
  driverName: string
  driverLicenseNumber: string
  driverLicenseState: string
  trailerNumber: string
  transactionStatus: string,
}

export interface TransactionProduct {
  productName:string,
  productType: string
  productQuantity: number,
  productQuantityUnit: string,
  taxRate: number,
  totalAmount: number,
  surchargeAmount: number,
  discrepancyAmount: number,
}

export interface ReportTransactionGroupedSearchParam {
  customerId : number
  groupBy : string
  orderBy : string
  startDate : string
  endDate : string

  cardType : string
  cardNumber : string

  driverId : number
  driverName : string
  vehicleNumber : string

  currency : string
  state : string
  city : string
}

export interface FuelTransactionSearchParam {
  customerId : number
  fuels : string[]
  orderBy : string
  startDate : string
  endDate : string

  cardType : string
  cardNumber : string

  driverId : number
  driverName : string
  vehicleNumber : string

  currency : string
  state : string
  city : string
}

export interface CardsReportSearchParam {
  cardType : string
  cardNumber : string
  cardStatus : string

  driverId : string
  firstName : string
  lastName : number

  orderBy : string
  vehicleNumber : string
}

export interface Invoice {
  id: number,
  customerName: number,
  invoiceDate: string,
  currency: number,
  startDate: string,
  endDate: number,
  dueDate: string,
  count: number,
  grossAmount: string,
  discount : string,
  invoicedAmount: number,
  invoiceType: string,
  status: string,
}

export interface ReportFuelExportSearchParam {
  customerId : number
  startDate : string
  endDate : string

  currency : string
  cardType : string

}

export interface TotalRevenueReportSearchParam {
  startDate : string
  endDate : string
}
