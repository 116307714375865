export const pagination = { currentPage : 0, itemsPerPage : 10, totalElements :0, sizeOptions: [10,25,50,100] }

export function toUTCDate(date : Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().slice(0, 10)
}

export function currentDateUTC() {
    const date = new Date()
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().slice(0, 10)
}

export const cardTypes = {
    'COMDATA' : 'Comdata', 
    'ULTRAMAR' : 'Ultramar',
    // 'WEX' : 'Wex'  
}

export const cardStatus = {
    'ACTIVE' : 'Active', 
    'BLOCKED' : 'Blocked',
    'LOST' : 'Lost',
    //'DELETED' : 'Deleted' 
}

export const transactionStatus = {
    'INVOICED' : 'Invoice', 
    'COMPLETED' : 'Completed'
}

export const pricingOptions = {
    'COST_PLUS' : 'COST_PLUS', 
    'RETAIL_MINUS' : 'RETAIL_MINUS' 
}

export const vendors = { 
    'TA_PETRO' : 'TA_PETRO', 
    'IMPERIAL_OIL' : 'IMPERIAL_OIL',
    'FLEET7' : 'FLEET7', 
    'AMBEST' : 'AMBEST',
    'CASEY' : 'CASEY', 
    'ULTRAMAR' : 'ULTRAMAR' 
}

export const currencies = [ "USD", "CAD" ]
export const states = [
     "AL","AK","AZ","AR","CA","CO","CT","DE","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY", //for Canada
     "AB","BC","MB","NB","NL","NS","NT","NU","ON","PE","QC","SK","YT" //for US
]

export const ROUTES = {

    CARD_NEW : '/card/add',
    CARD_VIEW : '/card/view',
    CARD_UPDATE : '/card/edit',
    CARDS_VIEW : '/card',

    USER_NEW : '/user/add',
    USER_EDIT : '/user/edit',

    CUSTOMER_NEW : '/customer/add',
    CUSTOMER_VIEW : '/customer/view',
    CUSTOMER_EDIT : '/customer/edit',
    CUSTOMER_SELF_EDIT : '/profile/update',
    CUSTOMERS_VIEW : '/customer',
    
    DRIVER_NEW : '/driver/add',
    DRIVER_VIEW : '/driver/view/',
    DRIVER_EDIT : '/driver/edit/',
    DRIVERS_VIEW : '/driver',


    RATE_PLAN_ADD : '/rate-plan/add',
    RATE_PLAN_TERMINATE : '/rate-plan/terminate_plan',
    RATE_PLAN_VIEW : '/rate-plan/view',
    RATE_PLAN_EDIT : '/rate-plan/edit',

    
}
