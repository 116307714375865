import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Logger } from '../services/logger/logger.service';
import { LoaderService } from '../services/loader/loader.service';
import { MessageService } from '../services/message/message.service';
import { Router } from '@angular/router';
import { pagination } from '../data/constants';

export const skipLoader = 'skipInkerceptorLoader';
const log = new Logger('APIHandlerInterceptor');

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

  constructor(
    private router:Router,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers && request.headers.has(skipLoader)) {
      this.loaderService.isCheckingDomain.next(true);
      request.headers.delete(skipLoader);
    } else {
      this.loaderService.show();
    }

    this.setPageSize(request)

    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    return next.handle(request).pipe(      
      catchError((error) => {
        //log.error('Request error', error);

        const title = error.error?.title || error.statusText
        const errorMessage = error.error?.message || error.message
        
        if(error.status==401) {
          this.router.navigate(["/auth/login"])
          this.messageService.openSnackBar('Please log-in to continue.','OK','error-snack');
          return of()
        } 
        
        this.messageService.openErrorDialog({ title: title, msg: errorMessage, status: `${error.status}` });
        return of(errorMessage);
      }),
      finalize(() => {
        this.loaderService.isCheckingDomain.next(false);
        this.loaderService.hide();
      }),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && !environment.production) {
          //log.info(event,event.status);
        }
        return event;
      })
    );
  }

  pagination = { ...pagination }
  setPageSize(request: HttpRequest<unknown>) {
    request.params.keys().filter(key => key=="size").forEach(key => {
      if(pagination.sizeOptions.includes(Number(request.params.get(key)!!))) {
        localStorage.setItem("pageSize", request.params.get(key)!!)
      }
    });
  }
}
